import React from "react"
import { Provider } from "../theme"
import { Page, Container, Profile } from "../components"

export default () => (
  <Provider>
    <Page>
      <Container>
        <Profile />
      </Container>
    </Page>
  </Provider>
)
